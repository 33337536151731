.layout-footer {
    position: fixed;
    height: 1.5rem;
    z-index: 1000;
    left: 0;
    bottom: 0;
    width: 100vw;
    padding: 0 1rem;
    background-color: lightgrey;
    transition: left $transitionDuration;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
    font-size: 1em;
}
