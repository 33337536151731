/* General */
$fontSize: 14px;
$borderRadius: 12px;
$transitionDuration: 0.2s;

$primaryColor: #00bcd4;
$secondaryColor: #f44336;
$successColor: #4caf50;
$warningColor: #ff9800;
$infoColor: #2196f3;
$dangerColor: #f44336;
$lightColor: #f5f5f5;
$darkColor: #212121;
$callToActionColor: #ff9800;

