.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 6rem 1rem 1rem 1rem;
  transition: margin-left $transitionDuration;
  
}

.layout-main {
  // flex: 1 1 auto;
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  //   height: 100vh;
  
}

.dialog-card {
  background-color: var(--surface-card);
  padding: 1.5rem;
  // margin-bottom: 1rem;
  border-radius: $borderRadius;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08) !important;

}

.full-height {
  height: 100%;
}

.main-card {
  background-color: var(--surface-card);
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  border-radius: $borderRadius;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08) !important;

  

}

.main-card-content {
  overflow-y: scroll;
  overflow-x: visible;
  height: 100%;
}

.card-content {
  background-color: var(--surface-card);
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: $borderRadius;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.1),
    0px 1px 4px rgba(0, 0, 0, 0.16) !important;
}

