.charge-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
  
    &.charge-badge-active {
      background-color: #c8e6c9;
      color: #256029;
    }

    &.charge-badge-free {
      background-color: #c8e6c9;
      color: #256029;
    }
  
    &.charge-badge-new {
      background-color: #b3e5fc;
      color: #23547b;
    }
  
    &.charge-badge-sieved {
      background-color: #ffd8b2;
      color: #805b36;
    }
    &.charge-badge-harvested {
      background-color: #b3e5fc;
      color: #23547b;
    }
    &.charge-badge-inactive {
      background-color: #ffcdd2;
      color: #c63737;
    }
    &.charge-badge-occupied{
      background-color: #ffcdd2;
      color: #c63737;
    }
    &.charge-badge-error {
      background-color: #ffcdd2;
      color: #c63737;
    }
  }

  
.stack-badge {
    border-radius: 2px;
    // padding: 0 0.1em 0 ;
    aspect-ratio: 2/3;
    font-size: 10px;
    font-weight: 800;
    display: flex;
    align-items:center;
    justify-content: center;
    user-select: none;
  
    &.stack-badge-multiple-columns {
      height: 3em;
      // font-size: 8px;
    }
  
    &.stack-badge-active {
      background-color: #f9be8b;
      color: #000000;
    }
  
    &.stack-badge-empty {
      background-color: #f5f5f5;
      color: #9e9e9e;
    }
  
    &.stack-badge-occupied {
      background-color: #fcf282;
      color: #000000;
    }
  
    &.stack-badge-unused {
      visibility: hidden;
    }
  
    &.clickable {
      cursor: pointer;
  
      &:hover {
        background: rgba(79, 70, 229, 0.04);
        color: #4f46e5;
      }
    
      &.enabled {
        background: rgba(79, 70, 229, 0.04);
        color: #4f46e5;
        border: 1px solid;
      }
    
      &:focus {
        background: rgba(79, 70, 229, 0.16);
        color: #4f46e5;
        outline: 0 none;
        outline-offset: 0;
        transition: box-shadow 0.2s;
        box-shadow: inset var(--focus-ring);
      }
    }
  
  
  
    // &:focus {
    //   box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #6366f1, 0 1px 2px 0 rgb(0 0 0 / 0%);
    //   outline: 0 none;
    //   outline-offset: 0;
    // }
  
  
  
  }