.farm-container {
    background-color: var(--surface-card);
    // padding: 1rem;
    // margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.1),
      0px 1px 4px rgba(0, 0, 0, 0.16) !important;
    // aspect-ratio: 5/2;
    /*
      "contain" to see full original image with eventual empty space
      "cover" to fill empty space with truncating
      "fill" to stretch
    */
    // object-fit: contain;
    &.aspect-ratio-5-2 {
      aspect-ratio: 5/2;
      // object-fit: contain;
    }
    &.aspect-ratio-5 {
      aspect-ratio: 5;
      // object-fit: contain;
    }
    &.unused {
      opacity: 0.7;
      height: 100%;
      .stacksLayoutGrid {
        display: none;
      }
    }
  
    &.hide {
      visibility: hidden;
    }
  }
  
  .productionSiteGrid {
    display: grid;
    border: 1px solid var(--surface-d);
    border-radius: 5px;
    padding: 1rem;
  
    column-gap: 1em;
    row-gap: 1em;
    overflow-x: auto;
  }
  
  .stackLayoutGrid {
    display: grid;
    padding: 0.5rem;
    align-items: center;
    column-gap: 0.2rem;
    row-gap: 50%;
    height: 100%;
  
  }