.eurobox{
    width: 100%;
    position: relative;
 }

.eurobox-image {
    width: 100%;
    height: 100%;
 }

.text-on-image {
   position: absolute;
    top: 70%;   
   left: 50%;
   transform: translate(-50%, -50%);
   white-space: nowrap;
   
 }

 .container {
    width: 100%;
    height: auto;
    position: relative;

 }

 .container-image{
    width: 100%;
 }

 .text-on-container {
    position: absolute;
     top: 60%;   
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    
  }

