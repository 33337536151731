
.avatar {
   width: 200px;
  height: 200px;
   margin: auto;
   border: solid 1px #3f3f4683;
   border-radius: 50%;
   justify-content: center;
   align-items: center;
   display: flex;
 }
 .avatar img {
   width: 100%;
   //border: solid 6px transparent;
   border-radius: 50%;
 }

 .avatar i {
   font-size: 50px;
   color: #3f3f4683

   
 }

.username {
  font-size: 1.7em;
  font-weight: bold;
  margin-bottom: 0.5em;
  text-align: center;
}
.role {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
  text-align: center;
}
.phone{
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
  text-align: center;
}