.button-primary {
  background-color: #00a0d2;
  border-color: #00a0d2;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  white-space: nowrap;
  width: 100%;
}